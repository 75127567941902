import { AptlyAvailability, AptlyAvailabilityCalendar } from '@aptly-as/types';
import { useCallback, useContext, useMemo } from 'react';
import { SimpleModal } from '../../components/simple/SimpleCrud';
import { calendarSchema } from './booking.schema';
import i18n from '../../libraries/i18n';
import useGetApiUrl from '../../hooks/useGetApiUrl';
import { AvailabilityContext, CalendarContext } from './BookingContext';

type Props = {
  calendar?: AptlyAvailabilityCalendar;
  onClose?: () => void;
  onDestroy?: (availability: AptlyAvailability) => void;
};

export default function CalendarAddOrEdit(props: Props) {
  const availability = useContext(AvailabilityContext);
  const calendar = useContext(CalendarContext);
  const { onClose } = props;
  const endpoint = useGetApiUrl('project') + `/bookings/${availability.data!._id}`;
  const schema = useMemo(() => calendarSchema(), []);

  const handleOnEdit = useCallback(
    (newCalendar: AptlyAvailabilityCalendar) => {
      if (calendar.loaded) {
        calendar.setData(newCalendar);
      } else {
        availability.get();
      }
    },
    [calendar, availability]
  );

  return (
    <SimpleModal
      path={endpoint}
      data={calendar.data || {}}
      schema={schema}
      fields={['name', 'location', 'attendees', 'userAvailability', 'endAvailabilityAt', 'fee']}
      title={calendar.data ? calendar.data.name : i18n.t('actions.newCalendar')}
      onEdit={handleOnEdit}
      onClose={onClose}
    />
  );
}
