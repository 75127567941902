import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useContext, useMemo } from 'react';
import * as React from 'react';
import { NavLink, Route, useLocation, useNavigate, useParams } from 'react-router-dom';
import { ModalProvider } from '../../containers/Modal/ModalContext';
import useGetApiUrl, { SlugLevel } from '../../hooks/useGetApiUrl';
import Header from '../../components/Header';
import Breadcrumbs, { BreadcrumbsItem } from '../../components/Breadcrumbs';
import Typography from '../../mui/Typography.js';
import OrganizationHeader from '../Organization/OrganizationHeader';
import ProjectHeader from '../Project/ProjectHeader';
import { ListItem } from '../../components/List/List';
import Divider from '../../mui/Divider';
import apiRequest from '../../libraries/fetch/apiRequest';
import i18n from '../../libraries/i18n';
import StatusText from '../../components/StatusText';
import SidebarMenu from '../../containers/Sidebar/SidebarMenu';
import SidebarRoutes from '../../containers/Sidebar/SidebarRoutes';
import AppProject from '../App/AppProject';
import { UnitNotes } from '../Note/UnitNotes';
import { unitRoutes } from './unit.routes';
import Sidebar from '../../components/Sidebar';
import Layout from '../../components/Layout';
import Main from '../../components/Main';
import { ScopeProvider } from '../../libraries/scope/ScopeContext';
import { OrganizationContext } from '../Organization/OrganizationContext';
import ErrorBoundary from '../../components/ErrorBoundary';
import { ProjectContext } from '../Project/ProjectContext';
import { UnitContext, UnitProvider } from './UnitContext';

interface Params {
  slug: string;
  projectID: string;
  unitID: string;
  [key: string]: string;
}

export default function Unit() {
  const { unitID } = useParams<Params>();
  return (
    <UnitProvider key={unitID} id={unitID!}>
      <ModalProvider>
        <Content />
      </ModalProvider>
    </UnitProvider>
  );
}

function Content() {
  const { unitID } = useParams<Params>();
  const organization = useContext(OrganizationContext);
  const project = useContext(ProjectContext);
  const unit = useContext(UnitContext);
  const routes = useMemo(() => unitRoutes(), []);
  const navigate = useNavigate();
  const location = useLocation();
  const endpoint = useGetApiUrl('unit');
  const pathname = location.pathname;
  const slug = organization.data.slug;
  const projectID = project.data._id;

  const projectUrl = React.useMemo(
    () => `/organisasjoner/${slug}/prosjekter/${projectID}`,
    [slug, projectID]
  );
  const unitUrl = React.useMemo(() => `${projectUrl}/enheter`, [slug, projectID, unitID]);

  const handleOnNextUnit = React.useCallback(async () => {
    try {
      const nextUnit = await apiRequest<{ _id: string }>(`${endpoint}/next`);
      let path = pathname;
      const endsWithUnitId = new RegExp('enheter/[a-z0-9]{24}/?$');
      const endsWithId = new RegExp('[a-z0-9]{24}/?$');
      if (!pathname.match(endsWithUnitId)) {
        path = path.replace(endsWithId, '');
      }
      navigate(path.replace(unitID!, nextUnit._id));
    } catch (e) {
      navigate(unitUrl);
    }
  }, [endpoint, unitUrl, unitID, pathname, navigate]);

  const isLoadingData = !unit.data;

  return (
    <Layout>
      <Header
        head={[
          <Breadcrumbs key="breadcrumbs">
            <BreadcrumbsItem>
              <OrganizationHeader />
            </BreadcrumbsItem>
            <BreadcrumbsItem>
              <ProjectHeader />
            </BreadcrumbsItem>
            <BreadcrumbsItem>
              <Typography variant="body1">
                {unit.data ? unit.data.name : <StatusText>{i18n.t('statuses.loading')}…</StatusText>}
              </Typography>
            </BreadcrumbsItem>
          </Breadcrumbs>,
        ]}
      />
      {!isLoadingData && (
        <ScopeProvider path={`organizations/${slug}/projects/${projectID}/scope`}>
          <>
            <Sidebar>
              <ListItem component={NavLink} button to={`${projectUrl}/enheter`} icon={<ArrowBackIcon />}>
                {i18n.t('singles.customers')}
                <br />
                <i>{i18n.t('singles.units')}</i>
              </ListItem>
              <Divider />
              <SidebarMenu level={SlugLevel.Unit} routes={routes} injection="unit" />
              <Divider />
              <ListItem button type="button" onClick={handleOnNextUnit} icon={<ArrowForwardIcon />}>
                {i18n.t('actions.nextUnit')}
              </ListItem>
            </Sidebar>
            <Main>
              <ErrorBoundary>
                <SidebarRoutes routes={routes}>
                  <Route path="notater/:note" element={<UnitNotes />} />
                  <Route path="apps/:app" element={<AppProject />} />
                </SidebarRoutes>
              </ErrorBoundary>
            </Main>
          </>
        </ScopeProvider>
      )}
    </Layout>
  );
}
