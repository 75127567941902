import { AptlyFieldType, AptlyScopes, AptlyUnit, AptlyUnitRoom, AptlyUnitTemplate } from '@aptly-as/types';
import { useGet } from '@ewb/reach-react';
import { SelectChangeEvent } from '@mui/material/Select/SelectInput';
import TableFooter from '@mui/material/TableFooter';
import { ChangeEvent, useCallback, useContext, useMemo } from 'react';
import styled from 'styled-components';
import ActionBar from '../../components/ActionBar';
import ActionButtons from '../../components/actions/buttons/ActionButtons';
import { AddButtonWithIcon } from '../../components/actions/buttons/Buttons';
import DeleteIconEndpoint from '../../components/actions/icons/DeleteIconEndpoint';
import { DeleteIconButton, EditIconButton } from '../../components/actions/icons/Icons';
import { ICrudSchema } from '../../components/crud/utils/crud.utils';
import SearchLoader from '../../components/Search/SearchLoader';
import Section from '../../components/Section';
import CrudForm, {
  CrudFormContext,
  CrudFormTextField,
  useCrudFormField,
} from '../../components/simple/CrudForm';
import StatusText from '../../components/StatusText';
import { createModal } from '../../containers/Modal/ModalContext';
import { SlugLevel, useApiUrl } from '../../hooks/useGetApiUrl';
import i18n from '../../libraries/i18n';
import { getId } from '../../libraries/mongoose';
import { FragmentScope } from '../../libraries/scope/ScopeComponets';
import { ModalActions, ModalContent, ModalTitle } from '../../mui/Dialog';
import Grid from '../../mui/Grid';
import { Select, TextField } from '../../mui/Input';
import Paper from '../../mui/Paper';
import Table, { TableBody, TableCell, TableHead, TableRow } from '../../mui/Table';
import Typography from '../../mui/Typography';
import { onWheelCaptureBlur } from '../../utils/input';
import { minSpacing, tightSpacing } from '../../utils/spacing';
import { sizeParamOptions, sizeParamTranslations } from '../UnitTemplate/sizeParams';
import { UnitContext } from './UnitContext';

interface UnitRoom extends AptlyUnitRoom {
  hidden?: boolean;
  isRoom?: boolean;
}

const getUnitRooms = (unit: AptlyUnit, unitTemplate?: AptlyUnitTemplate) => {
  if (!unitTemplate) return [];
  const categories = unitTemplate.categories;
  const params: { [key: string]: any[] } = {};
  for (const category of categories) {
    params[category.name] = [];
    for (const section of category.sections) {
      for (const product of section.products) {
        if (product.unitSizeRoom) {
          if (!params[product.unitSizeRoom]) {
            params[product.unitSizeRoom] = [];
          }
          if (!params[product.unitSizeRoom].includes(product.unitSizeParam)) {
            params[product.unitSizeRoom].push(product.unitSizeParam);
          }
        }
        if (product.unitCostRoom) {
          if (!params[product.unitCostRoom]) {
            params[product.unitCostRoom] = [];
          }
          if (!params[product.unitCostRoom].includes(product.unitCostRoom)) {
            params[product.unitCostRoom].push(product.unitCostParam);
          }
        }
      }
    }
  }

  const rooms: UnitRoom[] = [...unit.rooms];
  for (const category of categories) {
    const index = rooms.findIndex((x) => x.name === category.name);
    if (index > -1) {
      if (category.isRoom) {
        rooms[index].isRoom = true;
      }
      continue;
    }
    const sizeParams = params[category.name].map((key) => ({
      key,
      value: 0,
    }));
    rooms.push({
      name: category.name,
      hidden: true,
      isRoom: category.isRoom,
      sizeParams: sizeParams,
    } as UnitRoom);
  }

  return rooms;
};

export function UnitRooms() {
  const baseEndpoint = useApiUrl(SlugLevel.Unit);
  const {
    data: unit,
    state: { busy: unitBusy },
    actions,
  } = useContext(UnitContext);
  const apiUrl = useApiUrl(SlugLevel.Project, `unit-templates/${getId(unit.unitTemplate)}`);
  const editRoomUrl = `${baseEndpoint}/rooms`;
  const [state] = useGet<AptlyUnitTemplate>(apiUrl);
  const rooms = useMemo(() => getUnitRooms(unit, state.data), [unit, state]);

  const editRoom = useCallback(
    (r: Partial<UnitRoom>) => () =>
      createModal(
        <EditRoom
          endpoint={editRoomUrl}
          r={{
            _id: r._id,
            name: r.name,
            sizeParams: r.sizeParams,
          }}
          onSave={actions.read}
        />
      ),
    [editRoomUrl, actions]
  );

  return (
    <>
      {unitBusy || (state.busy && <SearchLoader />)}
      <Section>
        <Grid container justifyContent="space-between">
          <Typography variant="h1" gutterBottom>
            {i18n.t('singles.room')}
          </Typography>
          <FragmentScope scope={AptlyScopes.ProjectUnits} crud="U">
            <AddButtonWithIcon onClick={editRoom({ sizeParams: [{ key: 'kvmGulv', value: 0 } as any] })}>
              {i18n.t('actions.newRoom')}
            </AddButtonWithIcon>
          </FragmentScope>
        </Grid>
      </Section>
      <Section>
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{i18n.t('singles.name')}</TableCell>
                <TableCell>{i18n.t('singles.values')}</TableCell>
                <FragmentScope scope={AptlyScopes.ProjectUnits} crud="U">
                  <TableCell align="right">{i18n.t('singles.actions')}</TableCell>
                </FragmentScope>
              </TableRow>
            </TableHead>
            <TableBody>
              {rooms.map((r) => (
                <TableRow key={r._id}>
                  <TableCell>
                    {r.name}{' '}
                    {r.hidden && r.isRoom ? <StatusText>{i18n.t('statuses.optionsHidden')}</StatusText> : ''}
                  </TableCell>
                  <TableCell>
                    <Grid container spacing={tightSpacing}>
                      {r.sizeParams && r.sizeParams.length > 0 ? (
                        r.sizeParams.map((sp) => (
                          <Grid key={sp._id} item>
                            <Grid container spacing={minSpacing}>
                              <Grid item>
                                <StatusText noUppercase>
                                  {sp.key in sizeParamTranslations()
                                    ? sizeParamTranslations()[sp.key]
                                    : sp.key}
                                </StatusText>
                              </Grid>
                              <Grid item>{sp.value}</Grid>
                            </Grid>
                          </Grid>
                        ))
                      ) : (
                        <Grid item>
                          <StatusText>{i18n.t('paragraphs.noSizes')}</StatusText>
                        </Grid>
                      )}
                    </Grid>
                  </TableCell>
                  <FragmentScope scope={AptlyScopes.ProjectUnits} crud="U">
                    <TableCell>
                      {r.hidden ? (
                        <ActionBar>
                          <AddButtonWithIcon variant="text" size="small" onClick={editRoom(r)} />
                        </ActionBar>
                      ) : (
                        <ActionBar>
                          <EditIconButton onClick={editRoom(r)} />
                          <DeleteIconEndpoint
                            endpoint={editRoomUrl}
                            _id={r._id}
                            onClick={() => actions.read()}
                          />
                        </ActionBar>
                      )}
                    </TableCell>
                  </FragmentScope>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </Section>
    </>
  );
}

interface EditRoomProps {
  endpoint: string;
  onSave: () => void;
  r: Partial<UnitRoom>;
  onClose?: () => void;
}

function EditRoom({ endpoint, onSave, r, onClose }: EditRoomProps) {
  const onEdit = useCallback(() => {
    onSave();
    onClose!();
  }, [onSave, onClose]);

  const schema = useMemo(
    (): ICrudSchema<AptlyUnitRoom> => ({
      name: { type: AptlyFieldType.Text, label: i18n.t('singles.name'), defaultValue: r.name || '' },
      sizeParams: {
        type: AptlyFieldType.Custom,
        defaultValue: r.sizeParams || [],
        label: i18n.t('singles.roomValues'),
      },
    }),
    [r]
  );

  return (
    <CrudForm<AptlyUnitRoom> path={endpoint} onEdit={onEdit} schema={schema} data={r}>
      <ModalTitle>
        <Typography>{r._id ? i18n.t('actions.editRoom') : i18n.t('actions.newRoom')}</Typography>
      </ModalTitle>
      <ModalContent>
        <Grid container flexDirection="column" gap={2}>
          <CrudFormTextField name="name" />
          <EditRoomSizeParams />
        </Grid>
      </ModalContent>
      <ModalActions>
        <CrudFormContext.Consumer>
          {({ state }) => (
            <ActionButtons onClose={onClose} disabledSubmit={!state.dirty} disabled={state.busy} />
          )}
        </CrudFormContext.Consumer>
      </ModalActions>
    </CrudForm>
  );
}

function EditRoomSizeParams() {
  const sizeParams = useCrudFormField<AptlyUnitRoom, 'sizeParams'>('sizeParams');
  const sizeOptions = useMemo(
    () => sizeParamOptions().filter((x) => !sizeParams.field.value.some((y) => x.value === y.key)),
    [sizeParams]
  );

  const handleOnKeyChange = useCallback(
    (index: number, key: string) => {
      sizeParams.field.value[index].key = key;
      sizeParams.onChange([...sizeParams.field.value]);
    },
    [sizeParams]
  );
  const handleOnValueChange = useCallback(
    (index: number, value: number) => {
      sizeParams.field.value[index].value = value;
      sizeParams.onChange([...sizeParams.field.value]);
    },
    [sizeParams]
  );

  const handleOnAdd = useCallback(
    (e: SelectChangeEvent) => {
      const key = e.target.value;
      if (!key) return;
      sizeParams.onChange([
        ...sizeParams.field.value,
        { key: key === '__custom' ? '' : key, value: 0 } as any,
      ]);
    },
    [sizeParams]
  );
  const handleOnDelete = useCallback(
    (index: number) => {
      sizeParams.field.value.splice(index, 1);
      sizeParams.onChange([...sizeParams.field.value]);
    },
    [sizeParams]
  );

  return (
    <Paper>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell width={20} />
            <TableCell>{i18n.t('singles.type')}</TableCell>
            <TableCell align="right" width={140}>
              {i18n.t('singles.value')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sizeParams.field.value.map((sizeParam, i) => (
            <SizeParam
              key={i}
              sizeParam={sizeParam}
              onKeyChange={handleOnKeyChange}
              onValueChange={handleOnValueChange}
              onDelete={handleOnDelete}
              index={i}
            />
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell colSpan={3}>
              <Select
                name="key"
                value={i18n.t('actions.selectToAdd')}
                onChange={handleOnAdd}
                options={sizeOptions}
                fullWidth
              />
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </Paper>
  );
}

interface SizeParamProps {
  sizeParam: AptlyUnitRoom['sizeParams'][0];
  index: number;
  onKeyChange: (index: number, key: string) => void;
  onValueChange: (index: number, value: number) => void;
  onDelete: (index: number) => void;
}

function SizeParam({ sizeParam, index, onKeyChange, onValueChange, onDelete }: SizeParamProps) {
  const handleOnKeyChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      onKeyChange(index, e.target.value);
    },
    [onKeyChange, index]
  );
  const handleOnValueChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      onValueChange(index, Number(e.target.value));
    },
    [onValueChange, index]
  );
  const handleOnDelete = useCallback(() => {
    onDelete(index);
  }, [onDelete, index]);

  const keyLabel = useMemo(() => {
    const sizeParamLabel = sizeParamTranslations();
    return (
      sizeParamLabel[sizeParam.key] || (
        <TextField variant="standard" defaultValue={sizeParam.key} onChange={handleOnKeyChange} />
      )
    );
  }, [handleOnKeyChange]);

  return (
    <TableRow>
      <TableCell padding="checkbox">
        <DeleteIconButton size="small" onClick={handleOnDelete} />
      </TableCell>
      <TableCell>{keyLabel}</TableCell>
      <TableCell align="right">
        <EditNumberField
          onWheelCapture={onWheelCaptureBlur}
          defaultValue={sizeParam.value || 0}
          type="number"
          variant="standard"
          onChange={handleOnValueChange}
        />
      </TableCell>
    </TableRow>
  );
}

const EditNumberField = styled(TextField)`
  input {
    text-align: right;
  }
`;
