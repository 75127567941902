import { ReactNode } from 'react';
import styled from 'styled-components';
import { RelativeDrawer } from '../mui/Drawer';
import { MOBILE_BREAK, SIDE_MENU_DESKTOP_SIZE, SIDE_MENU_MODILE_SIZE } from '../utils/themes';

const Wrapper = styled.div`
  grid-area: sidebar;
  position: fixed;
  left: 0;
  top: 64px;
  bottom: 0;
  width: ${SIDE_MENU_DESKTOP_SIZE};

  @media only screen and (max-width: ${MOBILE_BREAK}px) {
    width: ${SIDE_MENU_MODILE_SIZE};
  }
`;

const Content = styled.div`
  margin-bottom: 60px;
`;

type SidebarProps = {
  children: ReactNode;
};

function Sidebar(props: SidebarProps) {
  const { children } = props;
  return (
    <Wrapper>
      <RelativeDrawer variant="permanent">
        <Content>{children}</Content>
      </RelativeDrawer>
    </Wrapper>
  );
}

export default Sidebar;
