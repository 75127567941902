import { AptlyProducer, AptlyUnit } from '@aptly-as/types';
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import { useCallback, useMemo } from 'react';
import ReferenceInput from '../../components/ReferenceInput';
import SearchViewComposer, {
  SearchField,
  SearchViewComposerProps,
} from '../../deprecated/Search/SearchViewComposer';
import { SlugLevel, useSlugs, useTo } from '../../hooks/useGetApiUrl';
import filesSaver from '../../libraries/files-saver';
import { intlCurrencyDecimal, intlQuantity } from '../../libraries/intl';
import { Switch, TextField } from '../../mui/Input';
import { TextLink } from '../../libraries/router/ComponentLink';
import Paper from '../../mui/Paper';
import StatusText from '../../components/StatusText';
import Table, { TableBody, TableCell, TableFooter, TableHead, TableRow } from '../../mui/Table';
import { ProductReport } from '../Report/report.types';
import renderParams from '../../models/UnitTemplate/renderParams';
import i18n from '../../libraries/i18n';
import { Export } from '../../models/Unit/Units';

export interface ReportProps {
  showPrice?: boolean;
  unit?: string;
  additionalActions?: SearchViewComposerProps['additionalActions'];
}

function Report({ showPrice, unit, additionalActions }: ReportProps) {
  const [organizationSlug, projectID] = useSlugs();
  const baseEndpoint = `organizations/${organizationSlug}/projects/${projectID}/units`;
  const toUnit = useTo(SlugLevel.Project, '/enheter');

  const _additionalActions = useCallback(
    (...args: any[]) => {
      const _actions = [<Export key="export" baseEndpoint={baseEndpoint} label={i18n.t('singles.report')} />];
      if (additionalActions) {
        // @ts-ignore
        _actions.unshift(...additionalActions(...args));
      }
      return _actions;
    },
    [additionalActions]
  );
  const menuActions = useCallback(
    (...args: any[]) => {
      const _actions = [
        {
          label: i18n.t('singles.orderList') + '.csv',
          onClick: () =>
            filesSaver({
              endpoint: `${baseEndpoint}/report/export`,
              data: { ...args[4], reportType: 'orderList', confirmed: true },
            }),
        },
      ];
      if (!unit) {
        _actions.unshift({
          label: i18n.t('singles.perUnit') + '.csv',
          onClick: () =>
            filesSaver({
              endpoint: `${baseEndpoint}/report/export`,
              data: { ...args[4], reportType: 'perUnit', confirmed: true },
            }),
        });
      }
      return _actions;
    },
    [unit]
  );

  const searchFields: SearchField[] = useMemo(() => {
    const _searchFields: SearchField[] = [
      {
        type: 'textfield',
        name: 'categoryText',
        element: <TextField label={i18n.t('singles.category')} fullWidth />,
      },
      {
        type: 'textfield',
        name: 'sectionText',
        element: <TextField label={i18n.t('singles.section')} fullWidth />,
      },
      {
        type: 'reference',
        name: 'producer',
        element: (
          <ReferenceInput<AptlyProducer>
            label={i18n.t('singles.producer')}
            endpoint="producers/search"
            labelKey="name"
            queryKey="name"
            singular
          />
        ),
      },
      {
        type: 'switch',
        name: 'confirmed',
        element: <Switch defaultChecked label={i18n.t('statuses.confirmed')} color="primary" />,
      },
    ];

    if (!unit) {
      _searchFields.unshift({
        type: 'reference',
        name: 'unit',
        element: (
          <ReferenceInput<AptlyUnit>
            label={i18n.t('singles.unit')}
            endpoint={`${baseEndpoint}/search`}
            labelKey="name"
            queryKey="name"
            singular
          />
        ),
      });
    }

    return _searchFields;
  }, []);

  const query = useMemo(
    () => ({
      ...(unit ? { unit } : {}),
      reportType: 'perProduct',
      confirmed: 'true',
    }),
    [unit]
  );

  return (
    <SearchViewComposer
      noInfiniteScroll
      searching
      title={i18n.t('singles.reports')}
      endpoint={`${baseEndpoint}/report`}
      query={query}
      searchFields={searchFields}
      additionalActions={_additionalActions}
      menuActions={menuActions}
      emptyStateIcon={<InsertChartOutlinedIcon />}
      dontUseTable
      initialQuery
    >
      {(report: ProductReport[] | any[]) => {
        let totalAmount = 0;
        let totalUnitCost = 0;

        return (
          <Paper>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{i18n.t('singles.product')}</TableCell>
                  <TableCell>{i18n.t('singles.productNumber')}</TableCell>
                  <TableCell>{i18n.t('singles.details')}</TableCell>
                  <TableCell>{i18n.t('singles.category')}</TableCell>
                  <TableCell>{i18n.t('singles.section')}</TableCell>
                  <TableCell>{i18n.t('singles.producer')}</TableCell>
                  <TableCell>{i18n.t('singles.parameters')}</TableCell>
                  <TableCell>{i18n.t('singles.unit')}</TableCell>
                  <TableCell align="right">{i18n.t('singles.quantity')}</TableCell>
                  {showPrice && <TableCell align="right">{i18n.t('singles.totalPrice')}</TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                {(report as ProductReport[]).map((r, k) => {
                  totalAmount = totalAmount + r.pick.quantity;

                  if (r.pick.unitCost) {
                    totalUnitCost = totalUnitCost + r.pick.quantity * r.pick.unitCost;
                  }

                  const producer = r.producer ? (
                    r.producer.name
                  ) : r.pick.product && r.pick.product.producer ? (
                    (r.pick.product.producer as AptlyProducer).name
                  ) : (
                    <StatusText>{i18n.t('statuses.nothing')}</StatusText>
                  );

                  const product =
                    (r.pick.variant && r.pick.product?.variants?.find((x) => x._id === r.pick.variant)) ||
                    r.pick.product;

                  return (
                    <TableRow key={k}>
                      <TableCell>
                        {product
                          ? product.name
                          : r.pick.text || <StatusText>{i18n.t('singles.NA')}</StatusText>}
                      </TableCell>
                      <TableCell>
                        {product ? product.productNumber : <StatusText>{i18n.t('singles.NA')}</StatusText>}
                      </TableCell>
                      <TableCell>
                        {product
                          ? ['color', 'style', 'material', 'length', 'width', 'height']
                              .map((key) => ((product as any)[key] ? (product as any)[key] : null))
                              .filter(Boolean)
                              .join(', ')
                          : null}
                      </TableCell>
                      <TableCell>{r.category}</TableCell>
                      <TableCell>{r.section}</TableCell>
                      <TableCell>{producer}</TableCell>
                      <TableCell>{renderParams(r.pick.params || [])}</TableCell>
                      <TableCell>
                        <TextLink to={r.unit ? `${toUnit}/${r.unit._id}` : '#'}>
                          {r.unit?.name || r.name}
                        </TextLink>
                      </TableCell>
                      <TableCell align="right">{intlQuantity(r.pick.quantity)}</TableCell>
                      {showPrice && (
                        <TableCell align="right">
                          {intlCurrencyDecimal(r.pick.quantity * r.pick.unitCost)}
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell>{i18n.t('singles.total')}</TableCell>
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell align="right">{intlQuantity(totalAmount)}</TableCell>
                  {showPrice && <TableCell align="right">{intlCurrencyDecimal(totalUnitCost)}</TableCell>}
                </TableRow>
              </TableFooter>
            </Table>
          </Paper>
        );
      }}
    </SearchViewComposer>
  );
}

export default Report;
