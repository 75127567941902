import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { AptlyScopes } from '@aptly-as/types';
import DeleteResourceButton from '../../components/actions/buttons/DeleteResourceButton';
import SettingsSection from '../../containers/Settings/SettingsSection';
import { SlugLevel, useApiUrl } from '../../hooks/useGetApiUrl';
import { LayoutScope, FragmentScope } from '../../libraries/scope/ScopeComponets';
import Section from '../../components/Section';
import Typography from '../../mui/Typography';
import MaxWidth from '../../components/MaxWidth';
import { containerWidth } from '../../utils/sizes';
import { ListItem } from '../../components/List/List';
import { rawSpacing } from '../../utils/spacing';
import Grid from '../../mui/Grid';
import i18n from '../../libraries/i18n';
import OrganizationIntegrations from './organizationSettings/OrganizationIntegrations';
import OrganizationModules from './organizationSettings/OrganizationModules';
import { OrganizationContext } from './OrganizationContext';
import CrudSettingsListField, {
  CrudSettingsListReference,
  CrudSettingsSelect,
  SingleSettingsListField,
  SingleSettingsListMedia,
} from '../../components/Settings/CrudSettingsListField';

function OrganizationSettings() {
  const navigate = useNavigate();
  const org = useContext(OrganizationContext);
  const { data } = org;
  const endpoint = useApiUrl(SlugLevel.Base, 'organizations');

  if (!data) {
    return null;
  }

  return (
    <>
      <Section>
        <Typography variant="h1">{i18n.t('singles.settings')}</Typography>
      </Section>
      <Section>
        <>
          <MaxWidth width={containerWidth}>
            <Grid container spacing={rawSpacing} direction="column" wrap="nowrap">
              <Grid item>
                <SettingsSection title={i18n.t('singles.general')}>
                  <CrudSettingsSelect crud={org} field="status" divider />
                  <CrudSettingsSelect crud={org} field="type" />
                  <CrudSettingsListField crud={org} field="name" divider />
                  <CrudSettingsListField crud={org} field="number" divider />
                </SettingsSection>
              </Grid>
              <Grid item>
                <SettingsSection title={i18n.t('singles.signing')}>
                  <CrudSettingsListReference crud={org} field="signApp" divider />
                  <CrudSettingsListReference crud={org} field="signMembers" divider />
                </SettingsSection>
              </Grid>
              <Grid item>
                <SettingsSection title={i18n.t('singles.payment')}>
                  <CrudSettingsListField crud={org} field="email" divider />
                  <CrudSettingsListField crud={org} field="vat" divider />
                  <CrudSettingsListField crud={org} field="invoiceEmail" />
                  <CrudSettingsListField crud={org} field="tripletexCustomerId" />
                </SettingsSection>
              </Grid>
              <Grid item>
                <SettingsSection title={i18n.t('address.billing')}>
                  <SingleSettingsListField crud={org} field="address.billing.line1" divider />
                  <SingleSettingsListField crud={org} field="address.billing.line2" divider />
                  <SingleSettingsListField crud={org} field="address.billing.zip" divider />
                  <SingleSettingsListField crud={org} field="address.billing.city" divider />
                  <SingleSettingsListField crud={org} field="address.billing.country" />
                </SettingsSection>
              </Grid>
              <FragmentScope scope={AptlyScopes.Organization} crud="C">
                <Grid item>
                  <SettingsSection title={i18n.t('singles.look')}>
                    <SingleSettingsListMedia crud={org} field="logo" divider />
                    <SingleSettingsListMedia crud={org} field="negativeLogoMedia" divider />
                    <SingleSettingsListMedia crud={org} field="projectLogoMedia" divider />
                    <CrudSettingsListField crud={org} field="slug" divider />
                  </SettingsSection>
                </Grid>
                <Grid item>
                  <SettingsSection title={i18n.t('singles.producer')}>
                    <CrudSettingsListReference crud={org} field="producer" />
                  </SettingsSection>
                </Grid>
              </FragmentScope>
              <Grid item>
                <SettingsSection title={i18n.t('singles.visitingAddress')}>
                  <SingleSettingsListField crud={org} field="address.visit.street" divider />
                  <SingleSettingsListField crud={org} field="address.visit.zip" divider />
                  <SingleSettingsListField crud={org} field="address.visit.city" />
                </SettingsSection>
              </Grid>
              <Grid item>
                <SettingsSection title={i18n.t('singles.mailingAddress')}>
                  <SingleSettingsListField crud={org} field="address.post.street" divider />
                  <SingleSettingsListField crud={org} field="address.post.zip" divider />
                  <SingleSettingsListField crud={org} field="address.post.city" />
                </SettingsSection>
              </Grid>
              <LayoutScope item scope={AptlyScopes.Organization} crud="C">
                <OrganizationIntegrations organization={data} />
              </LayoutScope>
              <LayoutScope item scope={AptlyScopes.Organization} crud="C">
                <OrganizationModules
                  organization={data}
                  onSave={(data) => {
                    if (data) {
                      org.setData({ modules: data.modules });
                    }
                  }}
                />
              </LayoutScope>
              <LayoutScope item scope={AptlyScopes.Organization} crud="D">
                <Grid item>
                  <SettingsSection title={i18n.t('singles.other')}>
                    <CrudSettingsListField crud={org} field="phone" divider />
                    <ListItem
                      secondaryAction={
                        <DeleteResourceButton
                          endpoint={`${endpoint}/${data.slug}`}
                          onClick={() => navigate('/')}
                        >
                          {i18n.t('actions.archive')}
                        </DeleteResourceButton>
                      }
                    >
                      {i18n.t('actions.archiveOrganization')}
                    </ListItem>
                  </SettingsSection>
                </Grid>
              </LayoutScope>
            </Grid>
          </MaxWidth>
        </>
      </Section>
    </>
  );
}

export default OrganizationSettings;
