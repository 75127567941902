import { GridCallbackDetails } from '@mui/x-data-grid/models/api';
import { GridCellModesModel } from '@mui/x-data-grid/models/api/gridEditingApi';
import { GridPaginationMeta, GridPaginationModel } from '@mui/x-data-grid/models/gridPaginationProps';
import { GridCellEditStartParams } from '@mui/x-data-grid/models/params/gridEditCellParams';
import { useCallback, useContext, useState, JSXElementConstructor, useEffect, useMemo, useRef } from 'react';
import { DataGridProps, GridApiContext, GridColDef, GridRowId, GridRowModel } from '@mui/x-data-grid';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import DataGrid from '../../mui/x-data-grid/DataGrid';
import { SearchCrudContext } from './search.utils';
import i18n from '../../libraries/i18n';
import { IPaginatedSearchObject } from '../../libraries/reach/usePaginatedSearch';

type DataGridKeys = keyof Pick<
  DataGridProps,
  'isCellEditable' | 'isRowSelectable' | 'slots' | 'onRowClick' | 'initialState' | 'pageSizeOptions'
>;

export interface ISearchDataGridProps extends Partial<Pick<DataGridProps, DataGridKeys>> {
  columns: GridColDef[];
  Toolbar?: JSXElementConstructor<{}>;
  pageSize?: number;
}

export default function SearchDataGrid<T extends IPaginatedSearchObject>({
  columns,
  Toolbar = SearchDataGridToolbar,
  pageSize,
  pageSizeOptions = [5, 10, 20, 40, 100],
  ...rest
}: ISearchDataGridProps) {
  const editRef = useRef<GridCellEditStartParams | null>(null);
  const { items, actions, info, options, scope } = useContext(SearchCrudContext);
  const { setLimit, next, patchRow } = actions;
  const [page, setPage] = useState(0);
  const paginationMeta: GridPaginationMeta = useMemo(
    () => ({ hasNextPage: info.count > items.length }),
    [info.count, items.length]
  );
  const paginationModel: GridPaginationModel = useMemo(
    () => ({
      pageSize: pageSize || (info.limit > 100 ? 100 : info.limit),
      page,
    }),
    [pageSize, info.limit, page]
  );

  useEffect(() => {
    if (page > 0 && info.skip === 0) {
      setPage(0);
    }
  }, [page, info.skip, info.limit]);

  const handleOnPageSizeChange = useCallback(
    async (model: GridPaginationModel) => {
      if (info.page !== model.page) {
        await next(undefined, model.page);
        setPage(model.page);
      }
      if (info.limit !== model.pageSize) {
        setLimit(model.pageSize);
      }
    },
    [setLimit, info.limit, info.page]
  );

  const onCellEditStart = useCallback((params: GridCellEditStartParams) => {
    editRef.current = params;
  }, []);

  const handleOnCellModesChange = useCallback(
    (modes: GridCellModesModel, details: GridCallbackDetails) => {
      if (Object.keys(modes).length === 0 && editRef.current) {
        const { id, field } = editRef.current;
        const value = details.api.getCellValue(id, field);
        if (value !== editRef.current.value) {
          patchRow(String(id), { [field]: value } as Partial<T>);
        }
        editRef.current = null;
      }
    },
    [patchRow]
  );

  return (
    <DataGrid
      getRowId={(row) => row._id}
      columns={columns}
      rows={items}
      paginationMode="server"
      rowCount={info.count}
      estimatedRowCount={info.count}
      checkboxSelection={!options.disableBulk && scope.update}
      pagination
      autoHeight
      disableRowSelectionOnClick
      pageSizeOptions={pageSizeOptions}
      onPaginationModelChange={handleOnPageSizeChange}
      paginationModel={paginationModel}
      paginationMeta={paginationMeta}
      loading={info.busy}
      onCellModesModelChange={handleOnCellModesChange}
      onCellEditStart={onCellEditStart}
      isCellEditable={() => scope.update}
      isRowSelectable={() => scope.update}
      slots={{ toolbar: Toolbar, noRowsOverlay: NoRowsOverlay }}
      {...rest}
    />
  );
}

export interface ISearchDataGridToolbarProps<T extends IPaginatedSearchObject> {
  children?: (active: Map<GridRowId, GridRowModel>, getItems: () => T[]) => any;
}

export function SearchDataGridToolbar<T extends IPaginatedSearchObject>({
  children,
}: ISearchDataGridToolbarProps<T>) {
  const { actions, options, scope } = useContext(SearchCrudContext);
  const { spawnBulkPatch, spawnBulkDelete } = actions;
  const api: any = useContext(GridApiContext);

  const getItems = useCallback(() => {
    if (api) {
      const rows = api.current.getSelectedRows();
      return [...rows].map((x) => x[1]) as T[];
    }
    return [];
  }, [api]);

  const handleOnBatchEdit = useCallback(() => {
    spawnBulkPatch(getItems());
  }, [spawnBulkPatch, getItems]);
  const handleOnBatchDelete = useCallback(() => {
    spawnBulkDelete(getItems());
  }, [spawnBulkDelete, getItems]);

  if (!api || options.disableBulk || !scope.update) return null;

  const active = api.current.getSelectedRows();

  return (
    <Grid container>
      <Grid item>
        <Button size="small" disabled={active.size === 0} onClick={handleOnBatchEdit}>
          {i18n.t('actions.edit')}
        </Button>
      </Grid>
      {scope.delete && (
        <Grid item>
          <Button size="small" disabled={active.size === 0} onClick={handleOnBatchDelete}>
            {i18n.t('actions.delete')}
          </Button>
        </Grid>
      )}
      {children ? children(active, getItems) : null}
    </Grid>
  );
}

export function NoRowsOverlay() {
  return (
    <Grid container justifyContent="center" alignItems="center" style={{ height: '100%' }}>
      {i18n.t('paragraphs.searchOrAdd')}
    </Grid>
  );
}
