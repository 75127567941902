import { AptlyOrder, AptlyOrganization, AptlyProject, AptlyScopes, AptlyUnit } from '@aptly-as/types';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { GridColDef, GridFooter } from '@mui/x-data-grid';
import Decimal from 'decimal.js';
import { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { SearchExportButton } from '../../components/actions/buttons/ExportButton';
import { useProjectReference } from '../../components/crud/schema/reference.schemas';
import Search, {
  ISearchProps,
  SearchFieldDate,
  SearchFieldReference,
  SearchFieldSelect,
  SearchFieldText,
} from '../../components/Search/Search';
import {
  dataGridActionProps,
  DataGridActionWrapper,
  DataGridGoToAction,
} from '../../components/Search/search-data-grid/data-grid.actions';
import { toSearchPatchPath } from '../../components/Search/search.utils';
import SearchDataGrid, { ISearchDataGridProps } from '../../components/Search/SearchDataGrid';
import { SlugLevel, useApiUrl, useTo } from '../../hooks/useGetApiUrl';
import i18n from '../../libraries/i18n';
import { intlCurrencyDecimal } from '../../libraries/intl';
import { getId } from '../../libraries/mongoose.js';
import { DocumentPreviewIcon } from '../Document/DocumentPreview';
import { orderStatusLabel, orderStatusOptions } from './order.utils';
import { OrderPaymentAction } from './OrderPayment';

const columns = (): GridColDef<AptlyOrder>[] => {
  const _columns: GridColDef[] = [
    {
      type: 'string',
      field: 'orderNumber',
      headerName: i18n.t('singles.orderNumber'),
      flex: 1,
    },
    {
      type: 'string',
      field: 'organization',
      headerName: i18n.t('singles.organization'),
      width: 200,
      valueGetter: (value: AptlyOrganization) => value?.name || value,
    },
    {
      type: 'string',
      field: 'project',
      headerName: i18n.t('singles.project'),
      width: 150,
      valueGetter: (value: AptlyProject) => value?.name || value,
    },
    {
      type: 'string',
      field: 'unit',
      headerName: i18n.t('singles.unit'),
      width: 150,
      valueGetter: (value: AptlyUnit) => value?.name || value,
    },
    {
      type: 'string',
      field: 'status',
      headerName: i18n.t('singles.status'),
      width: 200,
      valueGetter: (value) => orderStatusLabel(value),
    },
    {
      type: 'date',
      field: 'created',
      headerName: i18n.t('statuses.created'),
      width: 150,
      valueGetter: (value) => new Date(value),
    },
    {
      type: 'number',
      field: 'totalCost',
      headerName: i18n.t('singles.totalPrice'),
      width: 175,
      valueGetter: (value) => intlCurrencyDecimal(value || 0),
    },
    dataGridActionProps(
      (params) => {
        const order = params.row as AptlyOrder;
        const receipt = order.signedReceipt || order.receipt;
        const canDeduct = !!order.payment && !order.paymentSession?.capturedAt;
        const orderTo = useTo(SlugLevel.Project, '/ordre', { project: getId(order.project) });

        return (
          <DataGridActionWrapper>
            <DocumentPreviewIcon document={receipt} />
            {canDeduct && <OrderPaymentAction order={params.row} />}
            <DataGridGoToAction params={params} preTo={orderTo} />
          </DataGridActionWrapper>
        );
      },
      { minWidth: 125 }
    )
  ];

  return _columns;
};

export default function Orders() {
  const path = useApiUrl(SlugLevel.Project, 'orders');
  const gridColumns = useMemo(() => columns(), []);
  const initialState: ISearchDataGridProps['initialState'] = useMemo(
    () => ({
      columns: {
        columnVisibilityModel: {
          organization: !path.includes('organizations'),
          project: !path.includes('projects'),
          unit: path.includes('organizations'),
        },
      },
    }),
    [path]
  );

  const query = useMemo(() => {
    let select = '_id organization project unit status orderNumber created totalCost signedReceipt receipt';
    let $populate = '';
    if (path.includes('projects')) {
      $populate += ';unit:_id,name';
    } else if (path.includes('organizations')) {
      $populate += ';project:_id,name;unit:_id,name';
    } else {
      $populate += ';organization:_id,name;project:_id,name';
    }
    return {
      select,
      $populate,
      sort: '-created',
    };
  }, [path]);

  const refProps = useProjectReference('units');
  const searchFields = useMemo(() => {
    const fields: ISearchProps<AptlyOrder>['searchFields'] = [
      <SearchFieldText
        key="orderNumber"
        field="orderNumber"
        queryKey="$orderNumber"
        label={i18n.t('singles.orderNumber')}
      />,
      <SearchFieldReference
        key="unit"
        field="unit"
        referenceProps={{
          ...refProps,
          label: i18n.t('singles.unit'),
        }}
      />,
      [{ xs: '2' }, <SearchFieldSelect key="status" field="status" options={orderStatusOptions()} />],
      [
        { xs: '2' },
        <SearchFieldDate
          key="$gte_created"
          field="$gte_created"
          queryKey="$gte_created"
          label={i18n.t('singles.from')}
          autoFocus
        />,
      ],
      [
        { xs: 2 },
        <SearchFieldDate
          key="$lt_created"
          field="$lt_created"
          queryKey="$lt_created"
          label={i18n.t('singles.to')}
          autoFocus
        />,
      ],
    ];
    return fields;
  }, [refProps]);

  const buttonActions = useCallback(
    () => [<SearchExportButton key="export" endpoint={`${path}/export`} query={query} />],
    [path, query]
  );

  return (
    <Search<AptlyOrder>
      path={path}
      patchPath={toSearchPatchPath('orders')}
      scope={AptlyScopes.Admin}
      header={{ title: i18n.t('singles.orders') }}
      post={{ title: i18n.t('singles.orders') }}
      patch={{ title: i18n.t('singles.orders') }}
      options={{ disableBulk: true, defaultShow: true }}
      reach={{ query }}
      buttonActions={buttonActions}
      searchFields={searchFields}
    >
      {(items) => (
        <SearchDataGrid initialState={initialState} columns={gridColumns} slots={{ footer: footer(items) }} />
      )}
    </Search>
  );
}

const footer = (items: AptlyOrder[]) => () => {
  const total = useMemo(() => items.reduce((tot, item) => tot.add(item.totalCost), new Decimal(0)), []);
  return (
    <Grid container justifyContent="space-between" alignItems="center" gap={1}>
      <TotalText variant="subtitle1">
        {i18n.t('singles.total')}: {intlCurrencyDecimal(total.toNumber())}
      </TotalText>
      <GridFooter />
    </Grid>
  );
};

const TotalText = styled(Typography)`
  margin-left: ${(props) => props.theme.spacing()};
`;
