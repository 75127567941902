import { useContext } from 'react';
import { AptlyScopes } from '@aptly-as/types';
import { GoToButtonLinkWithIcon } from '../../components/actions/buttons/Buttons';
import Section from '../../components/Section';
import useGetApiUrl, { SlugLevel, useSlugs, useTo } from '../../hooks/useGetApiUrl';
import i18n from '../../libraries/i18n';
import { getId } from '../../libraries/mongoose';
import Scope from '../../libraries/scope/Scope';
import Grid from '../../mui/Grid';
import Typography from '../../mui/Typography';
import { useProject } from '../Project/ProjectContext';
import Categories from './Tree/Categories';
import { UnitContext } from './UnitContext';

export function UnitOptionList() {
  const projectData = useProject();
  const { data: unit, actions } = useContext(UnitContext);
  const to = useTo(SlugLevel.Project, `/tilvalg/${getId(unit.unitTemplate)}`);
  const baseEndpoint = useGetApiUrl('project', '/units');
  const [organizationSlug, projectID, unitID] = useSlugs();

  return (
    <>
      <Section>
        <Grid container justifyContent="space-between">
          <Typography variant="h1">{i18n.t('singles.optionList')}</Typography>
          <GoToButtonLinkWithIcon to={to}>{i18n.t('actions.edit')}</GoToButtonLinkWithIcon>
        </Grid>
      </Section>
      <Categories
        organizationSlug={organizationSlug}
        projectID={projectID}
        endpoint={`${baseEndpoint}/${unitID}/categories`}
        algorithms={projectData.algorithms}
        selectedOptions={unit.selectedOptions}
        unitEndpoint={`${baseEndpoint}/${unit._id}`}
        categoryOverrides={unit.categoryOverrides}
        productOverrides={unit.productOverrides}
        onSave={actions.read}
        rooms={unit.rooms || []}
        canUpdate={Scope.crud(AptlyScopes.ProjectUnits, 'U')}
      />
    </>
  );
}
