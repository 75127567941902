import { AptlyScopes, AptlyUnitInjection } from '@aptly-as/types';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import DashboardIcon from '@mui/icons-material/DashboardOutlined';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import NoteIcon from '@mui/icons-material/Note';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import StyleOutlinedIcon from '@mui/icons-material/StyleOutlined';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import LivingOutlinedIcon from '@mui/icons-material/LivingOutlined';
import { SidebarList } from '../../containers/Sidebar/sidebar.utils';
import { SlugLevel } from '../../hooks/useGetApiUrl';
import i18n from '../../libraries/i18n';
import Typography from '../../mui/Typography';
import { Documents } from '../Document/Documents';
import Inquiries from '../Inquiry/Inquiries';
import { UnitNotes } from '../Note/UnitNotes';
import UnitOrders from '../Order/UnitOrders';
import UnitDashboard from './UnitDashboard';
import UnitGeneralFields from './UnitGeneralFields';
import UnitOptionChanges from './UnitOptionChanges';
import { UnitOptionList } from './UnitOptionList';
import UnitReports from './UnitReports';
import { UnitRooms } from './UnitRooms';
import UnitSelections from './UnitSelections';

export const unitRoutes = (): SidebarList<AptlyUnitInjection>[] => [
  {
    injection: AptlyUnitInjection.Base,
    scope: AptlyScopes.Unit,
    mobile: true,
    items: [
      {
        scope: AptlyScopes.Project,
        path: '',
        icon: <DashboardIcon />,
        label: i18n.t('singles.dashboard'),
        element: <UnitDashboard />,
        mobile: true,
      },
      {
        scope: AptlyScopes.ProjectUnitExtraFields,
        path: 'generelle-felt',
        icon: <TextFieldsIcon />,
        label: i18n.t('paragraphs.generalFields'),
        element: <UnitGeneralFields />,
      },
      {
        scope: AptlyScopes.Unit,
        path: 'documents',
        icon: <FileCopyOutlinedIcon />,
        label: i18n.t('singles.documents'),
        element: <Documents scope={AptlyScopes.Unit} level={SlugLevel.Unit} />,
      },
      {
        scope: AptlyScopes.UnitNotes,
        path: 'notater',
        icon: <NoteIcon />,
        label: i18n.t('singles.notes'),
        element: <UnitNotes />,
        mobile: true,
      },
    ],
  },
  {
    injection: AptlyUnitInjection.Options,
    scope: AptlyScopes.Unit,
    subheader: i18n.t('singles.options'),
    items: [
      {
        scope: AptlyScopes.Unit,
        path: 'rom',
        icon: <LivingOutlinedIcon />,
        label: i18n.t('singles.room'),
        element: <UnitRooms />,
      },
      {
        scope: AptlyScopes.Unit,
        path: 'tilvalgliste',
        icon: <StyleOutlinedIcon />,
        label: (
          <Typography variant="body2">
            {i18n.t('singles.catalog')}
            <br />
            <i>{i18n.t('singles.optionList')}</i>
          </Typography>
        ),
        element: <UnitOptionList />,
      },
      {
        scope: AptlyScopes.Unit,
        path: 'endringer',
        icon: <AddShoppingCartIcon />,
        label: i18n.t('singles.extraOptions'),
        element: <UnitOptionChanges />,
      },
    ],
  },
  {
    injection: AptlyUnitInjection.Reports,
    scope: AptlyScopes.Unit,
    subheader: i18n.t('singles.reports'),
    items: [
      {
        scope: AptlyScopes.Unit,
        path: 'tilvalg',
        icon: <ShoppingCartOutlinedIcon />,
        label: i18n.t('singles.options'),
        element: <UnitSelections />,
      },
      {
        scope: AptlyScopes.UnitReport,
        path: 'rapporter',
        icon: <InsertChartOutlinedIcon />,
        label: i18n.t('singles.reports'),
        element: <UnitReports />,
      },
      {
        scope: AptlyScopes.UnitOrders,
        path: 'ordre',
        icon: <ReceiptIcon />,
        label: i18n.t('singles.orders'),
        element: <UnitOrders />,
      },
    ],
  },
  {
    injection: AptlyUnitInjection.Support,
    scope: AptlyScopes.ProjectInquiry,
    subheader: i18n.t('singles.clientCommmunication'),
    mobile: true,
    items: [
      {
        scope: AptlyScopes.ProjectInquiry,
        to: 'kundesupport',
        path: 'kundesupport/*',
        icon: <ChatOutlinedIcon />,
        label: i18n.t('singles.support'),
        element: <Inquiries />,
        mobile: true,
      },
    ],
  },
  {
    injection: AptlyUnitInjection.Admin,
    scope: AptlyScopes.AdminTesting,
    subheader: i18n.t('singles.admin'),
    items: [],
  },
];
