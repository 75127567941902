import { AptlyDocumentAccess, AptlyDocumentType } from '@aptly-as/types';
import i18n from '../../libraries/i18n';
import { SelectOption } from '../../mui/Select';

export interface DocumentTypeOption {
  value: AptlyDocumentType;
  label: string;
}

export const defaultDocumentType = (): DocumentTypeOption => ({
  label: i18n.t('singles.document'),
  value: AptlyDocumentType.Document,
});

export const documentTypeOptions = (): DocumentTypeOption[] => [
  defaultDocumentType(),
  {
    label: i18n.t('singles.floorPlan'),
    value: AptlyDocumentType.UnitPlan,
  },
  {
    label: i18n.t('singles.elDrawing'),
    value: AptlyDocumentType.ElDrawing,
  },
  {
    label: i18n.t('singles.bathroomDrawing'),
    value: AptlyDocumentType.BathroomDrawing,
  },
  {
    label: i18n.t('singles.kitchenDrawing'),
    value: AptlyDocumentType.KitchenDrawing,
  },
  {
    label: i18n.t('singles.vvsDrawing'),
    value: AptlyDocumentType.VVSDrawing,
  },
  {
    label: i18n.t('singles.fdvDocument'),
    value: AptlyDocumentType.FDV,
  },
  {
    label: i18n.t('singles.manual'),
    value: AptlyDocumentType.Manual,
  },
  {
    label: i18n.t('singles.agreementAttachment'),
    value: AptlyDocumentType.OrderAttachment,
  },
  {
    label: i18n.t('singles.productAttachment'),
    value: AptlyDocumentType.ProductAttachment,
  },
  {
    label: i18n.t('statuses.public'),
    value: AptlyDocumentType.Public,
  },
  {
    label: i18n.t('singles.receipt'),
    value: AptlyDocumentType.Receipt,
  },
  {
    label: i18n.t('singles.signedReceipt'),
    value: AptlyDocumentType.SignedReceipt,
  },
  {
    label: i18n.t('singles.inspectionReport'),
    value: AptlyDocumentType.InspectionReport,
  },
  {
    label: i18n.t('singles.prospect'),
    value: AptlyDocumentType.Prospect,
  },
  {
    label: i18n.t('singles.endReport'),
    value: AptlyDocumentType.EndReport,
  },
  {
    label: i18n.t('singles.deliveryDescription'),
    value: AptlyDocumentType.DeliveryDescription,
  },
  {
    label: 'GDPR',
    value: AptlyDocumentType.Gdpr,
  },
  {
    label: i18n.t('singles.circuitDirectory'),
    value: AptlyDocumentType.CircuitDirectory,
  },
];

export function sortedDocumentTypeOptions(): DocumentTypeOption[] {
  return documentTypeOptions().sort((a, b) => a.label.localeCompare(b.label));
}

export function documentTypeTranslations(type: string): string {
  const typeName = documentTypeOptions().find((x) => x.value === type);
  return typeName ? typeName.label : type;
}

export const documentAccessOptions = (withInfo = false): SelectOption[] => [
  {
    value: AptlyDocumentAccess.Public,
    label: i18n.t('statuses.public') + (withInfo ? ' - ' + i18n.t('paragraphs.documentPublic') : ''),
  },
  {
    value: AptlyDocumentAccess.User,
    label: i18n.t('singles.user') + (withInfo ? ' - ' + i18n.t('paragraphs.documentUser') : ''),
  },
  {
    value: AptlyDocumentAccess.Admin,
    label: i18n.t('singles.admin') + (withInfo ? ' - ' + i18n.t('paragraphs.documentAdmin') : ''),
  },
];

export function documentAccessTranslations(type: string): string {
  const typeName = documentAccessOptions().find((x) => x.value === type);
  return typeName ? typeName.label : type;
}
