import { Fragment, useCallback, useContext, useMemo } from 'react';
import { NavLink, useMatch } from 'react-router-dom';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Avatar from '@mui/material/Avatar';
import styled from 'styled-components';
import { SlugLevel, useTo } from '../../hooks/useGetApiUrl';
import { useMobile } from '../../hooks/useMobile';
import Scope from '../../libraries/scope/Scope';
import { ListItemScope, ListScope } from '../../libraries/scope/ScopeComponets';
import { ListItem, ListItemProps, ListSubheader } from '../../components/List/List';
import Divider from '../../mui/Divider';
import { MOBILE_BREAK } from '../../utils/themes';
import {
  ISidebarListItem,
  ISidebarProviderProps,
  SidebarContext,
  SidebarList,
  SidebarProvider,
} from './sidebar.utils';

interface ISidebarMenuProps extends ISidebarProviderProps {
  routes: SidebarList<any>[];
}

export default function SidebarMenu({ level, injection, routes }: ISidebarMenuProps) {
  return (
    <SidebarProvider level={level} injection={injection}>
      {routes.map((list, i) => (
        <Fragment key={String(i)}>
          <BaseList list={list} level={level} />
          <Divider />
        </Fragment>
      ))}
    </SidebarProvider>
  );
}

interface IBaseListProps extends Pick<IBaseListItemProps, 'nested'> {
  list: SidebarList<any>;
  level: SlugLevel;
}

function BaseList({ list, level, nested }: IBaseListProps) {
  const isMobile = useMobile();
  const { menus, active, setActive } = useContext(SidebarContext);

  const handleToggle = useCallback(
    (key: any) => () => {
      setActive((s) => ({ ...s, [key]: !s[key] }));
    },
    [setActive]
  );
  const subHeader = useMemo(() => {
    if (!list.subheader) return undefined;
    if (isMobile) return null;
    return <ListSubheader>{list.subheader}</ListSubheader>;
  }, [list.subheader]);

  const injectedList = useMemo(() => {
    const trueList = [...list.items];
    for (const injection of menus) {
      if (list.injection === injection.menu) {
        trueList.push({
          scope: injection.model,
          label: injection.label,
          path: `apps/${injection.app}/${injection._id}`,
          element: <></>,
          icon: injection.icon ? (
            <Avatar
              sx={{ marginLeft: '2px', height: 30, width: 30 }}
              variant="square"
              src={injection.icon?.url || injection.icon?.src}
              alt={injection.label}
              imgProps={{ style: { objectFit: 'contain' } }}
            >
              {injection.label[0]}
            </Avatar>
          ) : undefined,
        });
      }
    }
    return trueList;
  }, [list, menus]);

  if ((isMobile && !list.mobile) || injectedList.length === 0) {
    return null;
  }

  return (
    <ListScope scope={list.scope} crud={list.crud} every={list.every} subheader={subHeader}>
      {injectedList.map((itemOrList, i) => {
        if (isMobile && !itemOrList.mobile) {
          return null;
        }
        if ('path' in itemOrList) {
          return <BaseListItem key={String(i)} level={level} item={itemOrList} nested={nested} />;
        }
        const activeKey = `${list.injection}-${itemOrList.injection}`;
        return (
          <Fragment key={String(i)}>
            <StyledListItemScope
              scope={itemOrList.scope}
              icon={itemOrList.icon}
              secondaryIcon={active[activeKey] ? <ExpandLess /> : <ExpandMore />}
              button
              onClick={handleToggle(activeKey)}
            >
              {itemOrList.label || 'N/A'}
            </StyledListItemScope>
            <Collapse in={active[activeKey]} timeout="auto" unmountOnExit>
              <BaseList list={itemOrList} level={level} nested />
            </Collapse>
          </Fragment>
        );
      })}
    </ListScope>
  );
}

interface IBaseListItemProps extends Pick<ListItemProps, 'nested'> {
  item: ISidebarListItem;
  level: SlugLevel;
}

function BaseListItem({ item, level, nested }: IBaseListItemProps) {
  const to = useTo(level, `/${item.to || item.path}`);
  const active = useMatch(to);
  if (!Scope.crud(item.scope, item.crud, item.every)) {
    return null;
  }
  return (
    <StyledListItem
      button
      icon={item.icon}
      nested={item.nested || nested}
      component={NavLink}
      to={to}
      className={active ? 'item-active' : ''}
    >
      {item.label || item.children}
    </StyledListItem>
  );
}

const StyledListItemScope = styled(ListItemScope)`
  svg {
    fill: ${(props) => props.theme.palette.secondary.main};
  }
`;

const StyledListItem = styled(ListItem)`
  svg {
    fill: ${(props) => props.theme.palette.secondary.main};
  }
  &.item-active {
    background-color: ${(props) => props.theme.palette.secondary.light};
    color: ${(props) => props.theme.palette.secondary.contrastText};
  }
  &.item-active svg {
    fill: ${(props) => props.theme.palette.secondary.contrastText};
  }

  @media only screen and (max-width: ${MOBILE_BREAK}px) {
    padding: ${(props) => props.theme.spacing(1)};
    .MuiListItemText-root {
      display: none;
    }
  }
`;
