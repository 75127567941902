import { AptlyDraftJS, AptlyMarkdown } from '@aptly-as/types';
import styled from 'styled-components';
import { setDangerouslyHtml } from '../utils/rich-text';

interface Props {
  value?: AptlyMarkdown | AptlyDraftJS;
}

export function EditorText({ value }: Props) {
  if (!value) return null;
  return <Text dangerouslySetInnerHTML={setDangerouslyHtml(value)} />;
}

const Text = styled.div`
  img {
    max-width: 100%;
  }
`;
