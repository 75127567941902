import { ProductPackage, SectionProduct } from '../UnitTemplate/unit-template.types';
import i18n from '../../libraries/i18n';

type FoundPackage = { found?: boolean };

function mergePackages(
  products: SectionProduct[],
  packages: (ProductPackage & FoundPackage)[]
): SectionProduct[] {
  const mergedArray: SectionProduct[] = [];

  for (let i = 0; i < products.length; i++) {
    const product = products[i];

    if (product.partOfPackage) {
      const pkg = packages.find((p) => p._id === product.partOfPackage);

      if (pkg) {
        pkg.found = true;
        const alreadyIndex = mergedArray.findIndex((m) => m.packageID === pkg._id);

        if (alreadyIndex === -1) {
          mergedArray.push(newProduct(pkg, product));
        }
      }
    } else {
      mergedArray.push(product);
    }
  }

  packages.forEach((pkg) => {
    if (!pkg.found) {
      mergedArray.push(newProduct(pkg));
    }
  });

  return mergedArray;
}

function newProduct(pkg: ProductPackage, product: Partial<SectionProduct> = {}) {
  return {
    _id: product._id,
    packageID: pkg._id,
    isPackage: true,
    standard: pkg.standard,
    algorithm: product.algorithm,
    availableProducts: product.availableProducts || [],
    amount: 0,
    inheritAmountFromCategory: false,
    packageName: pkg.name,
    product: product.product
      ? {
          ...product.product,
          name: `${pkg.name} (${i18n.t('singles.package')})`,
        }
      : undefined,
  };
}

export default mergePackages;
