import { AptlyOrder } from '@aptly-as/types';
import { useGet } from '@ewb/reach-react';
import { createContext, ReactNode, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import ApiError from '../../components/ApiError.js';
import { SlugLevel, useApiUrl } from '../../hooks/useGetApiUrl.js';

interface IOrderContext {
  order: AptlyOrder;
  path: string;
  get: () => Promise<AptlyOrder | null>;
}

export const OrderContext = createContext<IOrderContext>({
  order: {} as AptlyOrder,
  path: '',
  get: () => Promise.resolve(null),
});

export function OrderProvider({ children }: { children: ReactNode }) {
  const params = useParams<{ order: string }>();
  const path = useApiUrl(SlugLevel.Project, `orders/${params.order}`);
  const [state, get] = useGet<AptlyOrder>(
    path,
    undefined,
    useMemo(
      () => ({
        query: {
          populate:
            'unit:_id,name;payment:_id,customer,captured,captures,document,transaction,shipping,shippingDate,billing,card,splits;createdBy:_id,fullName',
        },
      }),
      []
    )
  );

  if (state.error) return <ApiError error={state.error} />;
  if (!state.data) return null;

  return <OrderContext.Provider value={{ order: state.data, get, path }}>{children}</OrderContext.Provider>;
}
