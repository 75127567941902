import { useCallback, useContext, useMemo } from 'react';
import { Trans } from 'react-i18next';
import PaymentIcon from '@mui/icons-material/Payment';
import { AptlyFieldType, AptlyOrder, AptlyPayment } from '@aptly-as/types';
import ActionButtons from '../../components/actions/buttons/ActionButtons';
import { EditIconButton } from '../../components/actions/icons/Icons';
import { ICrudSchema } from '../../components/crud/utils/crud.utils';
import { SearchContext } from '../../components/Search/search.utils';
import CrudForm, {
  CrudFormCheckbox,
  CrudFormContext,
  CrudFormDateTimeField,
  CrudFormTextField,
} from '../../components/simple/CrudForm';
import { useModal } from '../../containers/Modal/Modal';
import { SlugLevel, useApiUrl } from '../../hooks/useGetApiUrl';
import i18n from '../../libraries/i18n';
import { ModalActions, ModalContent, ModalTitle } from '../../mui/Dialog';
import Grid from '../../mui/Grid';
import Typography from '../../mui/Typography';

interface OrderPaymentActionProps {
  order: AptlyOrder;
}

export function OrderPaymentAction({ order }: OrderPaymentActionProps) {
  const search = useContext(SearchContext);
  const spawnModal = useModal(() => <OrderPaymentModal order={order} onSave={search.refresh} />);
  return <EditIconButton onClick={spawnModal} icon={<PaymentIcon />} title={i18n.t('actions.setDelivery')} />;
}

interface OrderPaymentProps {
  order: AptlyOrder;
  onSave: () => void;
  onClose?: () => void;
}

interface PaymentBody {
  shippingDate: string;
  amount: number;
  description: string;
  terms: boolean;
}

export function OrderPaymentModal({ order, onSave, onClose }: OrderPaymentProps) {
  const path = useApiUrl(SlugLevel.Project, `orders/${order._id}/payment/capture`);
  const onEdit = useCallback(() => {
    onSave();
    onClose!();
  }, [onSave, onClose]);
  const schema = useMemo(
    (): ICrudSchema<PaymentBody> => ({
      amount: {
        type: AptlyFieldType.Number,
        defaultValue: 0,
        label: i18n.t('singles.captureAmount'),
        disabled: true,
      },
      description: {
        type: AptlyFieldType.Text,
        defaultValue: '',
        label: i18n.t('singles.captureDescription'),
      },
      shippingDate: {
        type: AptlyFieldType.Date,
        defaultValue: '',
        label: i18n.t('singles.shippingDate'),
        required: true,
        autoComplete: 'off',
      },
      terms: {
        type: AptlyFieldType.Checkbox,
        defaultValue: false,
        required: true,
        label: (
          <Trans i18nKey="paragraphs.captureTerms">
            I have read the
            <a
              href="https://aptly.no/vilkar/kjopsvilkar-for-platform"
              target="_blank"
              rel="noreferrer noopener"
            >
              terms and condition
            </a>
            and accept that the payment can be captured.
          </Trans>
        ),
      },
    }),
    []
  );
  const data = useMemo(
    (): PaymentBody => ({
      amount: order.totalCost - ((order.payment as AptlyPayment)?.captured.amount || 0),
      description: '',
      shippingDate: '',
      terms: false,
    }),
    [order]
  );
  return (
    <CrudForm path={path} data={data} schema={schema} onEdit={onEdit}>
      <ModalTitle>{order.orderNumber}</ModalTitle>
      <ModalContent>
        <Grid container gap={2}>
          <Typography>
            <Trans i18nKey="paragraphs.paymentModal">
              Capture payment from buyer. Be sure that you can capture the payment in terms of the
              requirements.
            </Trans>
          </Typography>
          <CrudFormTextField name="amount" />
          <CrudFormTextField name="description" />
          <CrudFormDateTimeField name="shippingDate" />
          <CrudFormCheckbox name="terms" />
        </Grid>
      </ModalContent>
      <CrudFormContext.Consumer>
        {({ state }) => (
          <ModalActions>
            <ActionButtons
              onClose={onClose}
              actionIcon={<PaymentIcon />}
              actionLabel={i18n.t('actions.capture')}
              disabled={state.busy}
            />
          </ModalActions>
        )}
      </CrudFormContext.Consumer>
    </CrudForm>
  );
}
