import { AptlyAlgorithm } from '@aptly-as/types';
import { useContext, useMemo } from 'react';
import { OrganizationContext } from '../Organization/OrganizationContext';
import { ProjectContext } from '../Project/ProjectContext';

export function useAlgorithms(): AptlyAlgorithm[] {
  const organization = useContext(OrganizationContext);
  const project = useContext(ProjectContext);
  return useMemo(() => {
    if (project.data) {
      return (project.data.algorithms as AptlyAlgorithm[]) || [];
    }
    if (organization.data) {
      return organization.data.algorithms || [];
    }
    return [];
  }, [organization, project]);
}

export function useAlgorithm(algorithm?: string | AptlyAlgorithm | null): AptlyAlgorithm | undefined {
  const algorithms = useAlgorithms();
  if (!algorithm) return undefined;
  if (typeof algorithm === 'object') {
    return algorithm;
  }
  return algorithms.find((x) => x._id === algorithm) || undefined;
}
