import {
  AptlyFieldType,
  AptlyJobOrder,
  AptlyJobQueue,
  AptlyOrder,
  AptlyOrderPaymentStatus,
  AptlyPeriod,
  AptlyProject,
  AptlyScopes,
  AptlyUnit,
} from '@aptly-as/types';
import Restore from '@mui/icons-material/Restore';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import DeleteIconEndpoint from '../../../components/actions/icons/DeleteIconEndpoint';
import { DoneIconButton, EditIconButton, GoToIconButtonLink } from '../../../components/actions/icons/Icons';
import BusyState from '../../../components/BusyState';
import Section from '../../../components/Section';
import { SimpleModal } from '../../../components/simple/SimpleCrud';
import EmptyState from '../../../containers/Error/EmptyState';
import ErrorState from '../../../containers/Error/ErrorState';
import { IJobOrderItem } from '../../../containers/Job/job.utils';
import { useJobSpawner } from '../../../containers/Job/Jobs.context';
import ConfirmModal from '../../../containers/Modal/ConfirmModal';
import { createModal } from '../../../containers/Modal/ModalContext';
import useApiRequest from '../../../hooks/useApiRequest';
import useGetApiUrl, { SlugLevel, useTo } from '../../../hooks/useGetApiUrl';
import i18n from '../../../libraries/i18n';
import { intlCurrency, intlDateTimeFormat } from '../../../libraries/intl';
import { getId } from '../../../libraries/mongoose';
import { reach } from '../../../libraries/reach/reach';
import Scope from '../../../libraries/scope/Scope';
import Grid from '../../../mui/Grid';
import Paper, { PaperPadded } from '../../../mui/Paper';
import Table, { TableBody, TableCell, TableHead, TableRow } from '../../../mui/Table';
import Typography from '../../../mui/Typography';
import HandleOrder, { handleOrderModelOptions } from '../../Order/HandleOrder/HandleOrder';
import { orderCompletedAt, orderStatusLabel } from '../../Order/order.utils';
import { ConfirmedPeriod } from '../unit.types';

interface Props {
  unit: AptlyUnit;
  project: AptlyProject;
  refresh: () => void;
}

export default function UnitDashboardPeriods({ project, unit, refresh }: Props) {
  const projectUrl = useGetApiUrl('project');
  const unitUrl = `${projectUrl}/units/${unit._id}`;
  const [busy, periods, error] = useApiRequest<AptlyPeriod[]>(`${unitUrl}/optionPeriods`);
  const [confirmedPeriods, setConfirmedPeriods] = useState(unit.confirmedPeriods);

  useEffect(() => {
    setConfirmedPeriods(unit.confirmedPeriods);
  }, [unit]);

  const allPeriods: PeriodType[] = useMemo(
    () =>
      periods
        ? periods.map((period, i) => {
            const confirmed = confirmedPeriods.find((confirmed) => confirmed.period === period._id);
            return {
              _id: (confirmed ? confirmed._id : period._id) || String(i),
              open: period.open,
              close: period.close,
              isConfirmed: !!confirmed,
              confirmed: confirmed ? confirmed.confirmed : undefined,
              order: confirmed ? confirmed.order : undefined,
              period: period._id || String(i),
            };
          })
        : [],
    [periods, confirmedPeriods]
  );

  const handleDelete = useCallback(
    (_id: string) => {
      setConfirmedPeriods((s) => [...s.filter((x) => x._id !== _id)]);
      refresh();
    },
    [refresh]
  );

  const handleConfirm = useCallback(
    (period: ConfirmedPeriod) => {
      setConfirmedPeriods((s) => [...s, period]);
      refresh();
    },
    [refresh]
  );

  if (busy) {
    return <BusyState />;
  }

  if (error) {
    return <ErrorState error={error} />;
  }

  return (
    <Section>
      <Grid container justifyContent="space-between" alignItems="center">
        <Typography variant="subtitle1" gutterBottom>
          {i18n.t('singles.periods')}
        </Typography>
      </Grid>
      {allPeriods.length === 0 ? (
        <PaperPadded>
          <EmptyState tiny>{i18n.t('statuses.noPeriods')}</EmptyState>
        </PaperPadded>
      ) : (
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{i18n.t('singles.period')}</TableCell>
                <TableCell>{i18n.t('singles.status')}</TableCell>
                <TableCell>{i18n.t('singles.order')}</TableCell>
                <TableCell>{i18n.t('singles.totalPrice')}</TableCell>
                <TableCell>{i18n.t('statuses.confirmed')}</TableCell>
                <TableCell>{i18n.t('statuses.completed')}</TableCell>
                <TableCell>{i18n.t('singles.period')}</TableCell>
                <TableCell align="right">{i18n.t('singles.actions')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allPeriods.map((confirmPeriod) => (
                <Period
                  key={confirmPeriod._id}
                  endpoint={unitUrl}
                  project={project}
                  confirmedPeriod={confirmPeriod}
                  onDelete={handleDelete}
                  onConfirm={handleConfirm}
                  refresh={refresh}
                  unit={unit}
                />
              ))}
            </TableBody>
          </Table>
        </Paper>
      )}
    </Section>
  );
}
type PeriodType = Pick<AptlyPeriod, 'open' | 'close'> & {
  _id: string;
  order?: ConfirmedPeriod['order'];
  period: string;
  isConfirmed?: boolean;
  confirmed?: string;
};

interface PeriodProps {
  endpoint: string;
  project: AptlyProject;
  confirmedPeriod: PeriodType;
  onDelete: (_id: string) => void;
  onConfirm: (period: ConfirmedPeriod) => void;
  unit: AptlyUnit;
  refresh: () => void;
}

function Period({ project, confirmedPeriod, endpoint, onDelete, onConfirm, unit, refresh }: PeriodProps) {
  const toUnitOrders = useTo(SlugLevel.Unit, '/ordre');
  const projectPeriod = project.periods.find((x) => x._id === confirmedPeriod.period);
  const unitOverride = projectPeriod
    ? unit.overridePeriods?.find((x) => x.period === projectPeriod._id)
    : undefined;
  const deleteEndpoint = `${endpoint}/confirmedPeriods`;
  const deleteOverridePeriodEndpoint = `${endpoint}/optionPeriods`;
  const handleCreateOrder = useCallback(async () => {
    createModal(
      <ConfirmCreateOrder
        endpoint={endpoint}
        unit={unit}
        confirmedPeriod={confirmedPeriod}
        onConfirm={onConfirm}
      />,
      { disableEscape: true }
    );
  }, [endpoint, confirmedPeriod, unit, onConfirm]);

  const handleOverride = React.useCallback(() => {
    createModal(
      <OverridePeriod unit={unit} unitOverride={unitOverride} period={projectPeriod!} refresh={refresh} />
    );
  }, [unit, unitOverride, projectPeriod, refresh]);

  if (!projectPeriod) {
    return null;
  }

  const order = confirmedPeriod.order as AptlyOrder | undefined;
  const showDelete = !!order;
  const showGoTo = !!order;

  const status = order
    ? orderStatusLabel(order.status)
    : confirmedPeriod.isConfirmed
      ? i18n.t('statuses.confirmed')
      : i18n.t('statuses.open');
  const orderNumber = order ? order.orderNumber : i18n.t('singles.NA');
  const totalCost = order ? order.totalCost : -1;
  const confirmed = confirmedPeriod.isConfirmed;
  const canReset = order?.paymentSession?.status !== AptlyOrderPaymentStatus.Captured;
  const completedAt = order ? orderCompletedAt(order) : '';
  return (
    <TableRow>
      <TableCell>{projectPeriod.name}</TableCell>
      <TableCell>{status}</TableCell>
      <TableCell>{orderNumber}</TableCell>
      <TableCell>{totalCost > -1 ? intlCurrency(totalCost) : i18n.t('singles.NA')}</TableCell>
      <TableCell>{confirmedPeriod.confirmed ? intlDateTimeFormat(confirmedPeriod.confirmed) : ''}</TableCell>
      <TableCell>{completedAt ? intlDateTimeFormat(completedAt) : '-'}</TableCell>
      <TableCell>
        {unitOverride?.close
          ? intlDateTimeFormat(unitOverride.close)
          : intlDateTimeFormat(projectPeriod.close)}
      </TableCell>
      <TableCell align="right">
        {Scope.crud(AptlyScopes.ProjectUnits, 'U') && (
          <>
            {!confirmed && (
              <>
                {unitOverride && (
                  <DeleteIconEndpoint
                    title={i18n.t('actions.overridePeriod')}
                    endpoint={deleteOverridePeriodEndpoint}
                    _id={confirmedPeriod._id}
                    onClick={refresh}
                  />
                )}
                <EditIconButton title={i18n.t('actions.overridePeriod')} onClick={handleOverride} />
              </>
            )}
            {confirmed && showDelete && canReset && (
              <DeleteIconEndpoint
                title={i18n.t('actions.openPeriod')}
                endpoint={deleteEndpoint}
                _id={confirmedPeriod._id}
                onClick={() => onDelete(confirmedPeriod._id)}
                icon={<Restore />}
              />
            )}
          </>
        )}
        {confirmed && showGoTo && (
          <GoToIconButtonLink to={toUnitOrders} title={i18n.t('actions.goToOrder')} />
        )}
        {Scope.crud(AptlyScopes.Unit, 'U') && (!confirmed || !order) && (
          <DoneIconButton title={i18n.t('actions.createOrder')} onClick={handleCreateOrder} />
        )}
      </TableCell>
    </TableRow>
  );
}

function OverridePeriod({
  unit,
  unitOverride,
  period,
  refresh,
  onClose,
}: {
  unit: AptlyUnit;
  unitOverride?: { close: string };
  period: AptlyPeriod;
  refresh: () => void;
  onClose?: () => void;
}) {
  const endpoint = useGetApiUrl('project', `/units/${unit._id}/optionPeriods`);
  const defaultValue = React.useMemo(() => {
    return unitOverride?.close ? dayjs(unitOverride.close) : dayjs(period.close);
  }, [unitOverride, period.close]);

  return (
    <SimpleModal<{ _id?: string; close: any }>
      path={endpoint}
      data={{ _id: period._id, close: defaultValue }}
      title={i18n.t('actions.overridePeriod')}
      onClose={onClose}
      onEdit={refresh}
      useFieldsProps={{ initWithGet: false }}
      schema={{
        close: {
          type: AptlyFieldType.DateTime,
          label: i18n.t('singles.to'),
          defaultValue: null,
          required: true,
          // @ts-ignore
          disablePast: true,
        },
      }}
      fields={['close']}
    />
  );
}

type IConfirmCreateOrderProps = Pick<PeriodProps, 'endpoint' | 'confirmedPeriod' | 'unit' | 'onConfirm'>;

function ConfirmCreateOrder({
  endpoint,
  confirmedPeriod,
  unit,
  onConfirm,
  ...rest
}: IConfirmCreateOrderProps) {
  const spawnJob = useJobSpawner<IJobOrderItem>();
  const handleCreateOrder = useCallback(async () => {
    const job = await createOrder(endpoint, confirmedPeriod, unit.name);
    spawnJob(job, {
      organization: getId(unit.organization),
      onComplete: ({ order }) => {
        onConfirm({ ...confirmedPeriod, order });
      },
      label: i18n.t('statuses.creatingOrder'),
      queue: AptlyJobQueue.Orders,
      progressSteps: [
        { progress: 25, label: i18n.t('statuses.creating') },
        { progress: 50, label: i18n.t('singles.documents') },
        { progress: 75, label: i18n.t('statuses.saving') },
      ],
      action: ({ job, onClose }) => (
        <DoneIconButton
          color="primary"
          onClick={() => {
            console.log({ unit });
            onClose();
            createModal(<HandleOrder unit={unit} order={job.returnvalue.order} />, handleOrderModelOptions);
          }}
        />
      ),
    });
  }, [spawnJob, endpoint, confirmedPeriod, unit, onConfirm]);

  return (
    <ConfirmModal
      busyLabel={i18n.t('statuses.creatingOrder')}
      title={i18n.t('actions.createOrder')}
      onConfirm={handleCreateOrder}
      actionLabel={i18n.t('actions.create')}
      {...rest}
    >
      {i18n.t('paragraphs.areYouSure')}
    </ConfirmModal>
  );
}

async function createOrder(endpoint: string, confirmedPeriod: PeriodType, unitName: string) {
  if (!confirmedPeriod.confirmed) {
    await reach.api(`${endpoint}/confirm`, {
      method: 'POST',
      body: {
        period: confirmedPeriod.period,
        name: unitName,
      },
    });
  }
  return await reach.api<AptlyJobOrder>(`${endpoint}/createOrderJob`, {
    method: 'POST',
    body: {
      period: confirmedPeriod.period,
    },
  });
}
