import { AptlyInquiry, AptlyInquiryStatus, AptlyUnit } from '@aptly-as/types';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Paper from '@mui/material/Paper';
import React from 'react';

import { SlugLevel, useApiUrl, useTo } from '../../hooks/useGetApiUrl';
import usePaginateSearch, { IUsePaginateSearchActions } from '../../hooks/usePagniateSearch';
import ErrorState from '../../containers/Error/ErrorState';
import { intlDateTimeFormat } from '../../libraries/intl';
import { getId, getPopulatedValue } from '../../libraries/mongoose';
import ComponentLink from '../../libraries/router/ComponentLink';
import Typography, { TypographyEllipsis } from '../../mui/Typography';
import i18n from '../../libraries/i18n';
import { ListItemTextPrimaryWithDate } from '../../components/List/ListItemTextPrimaryWithDate';
import { LoadMoreButton } from '../../components/actions/icons/LoadMoreButton';

interface Props {
  limit: number;
  level: SlugLevel;
}

export default function InquiryRecent({ limit, level }: Props) {
  const url = useApiUrl(level, 'inquiries');

  const [busy, inquiries, error, info, actions] = usePaginateSearch<AptlyInquiry>(
    url,
    React.useMemo(
      () => ({
        limit,
        query: {
          status: AptlyInquiryStatus.Open,
          sort: '-lastMessageSent',
          select: '_id,title,lastMessage,lastParticipant,lastMessageEmail,lastMessageSent,project,unit',
          $populate: 'lastParticipant:_id,fullName,profileImage;unit:_id,name',
        },
      }),
      [limit]
    )
  );

  if (error) {
    return (
      <ErrorState
        error={{ status: Number(error.status), title: String(error.code), error: String(error.code || '') }}
      />
    );
  }

  return (
    <List
      subheader={
        <ListSubheader>
          {i18n.t('paragraphs.newMessages')} ({info.count} {i18n.t('actions.open')})
        </ListSubheader>
      }
    >
      {inquiries.map((inquiry) => (
        <React.Fragment key={inquiry._id}>
          <InquiryRecentItem
            inquiry={inquiry}
            next={inquiries.length < info.count ? actions.next : undefined}
          />
          <Divider variant="inset" component="li" />
        </React.Fragment>
      ))}
      {inquiries.length < info.count && <LoadMoreButton next={actions.next} disabled={busy} />}
    </List>
  );
}

export interface IInquiryRecentItemProps extends Partial<Pick<IUsePaginateSearchActions, 'next'>> {
  inquiry: AptlyInquiry;
}

function InquiryRecentItem({ inquiry }: IInquiryRecentItemProps) {
  const to = useTo(SlugLevel.Project, `/kundesupport/${inquiry._id}`, {
    organization: getPopulatedValue('slug', inquiry.organization),
    project: getId(inquiry.project),
  });

  const user =
    inquiry.lastParticipant && typeof inquiry.lastParticipant === 'object'
      ? inquiry.lastParticipant
      : {
          fullName: inquiry.lastMessageEmail || 'N/A',
          profileImage: undefined,
        };

  return (
    <Paper>
      <ListItem button component={ComponentLink} to={to} style={{ display: 'flex' }}>
        <ListItemAvatar>
          <Avatar alt={user.fullName} src={user.profileImage || undefined} />
        </ListItemAvatar>
        <ListItemText
          primary={
            <ListItemTextPrimaryWithDate
              title={inquiry.title}
              date={intlDateTimeFormat(inquiry.lastMessageSent)}
            />
          }
          secondaryTypographyProps={{ component: 'div' }}
          secondary={
            <>
              <TypographyEllipsis component="span" variant="caption" color="text.primary" lines={2}>
                <span>{inquiry.lastMessage}</span>
                <br />
              </TypographyEllipsis>
              <Typography variant="caption">
                {(inquiry.unit as AptlyUnit)?.name || ''}&nbsp;-&nbsp;{user.fullName}
              </Typography>
            </>
          }
        />
      </ListItem>
    </Paper>
  );
}
