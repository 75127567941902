import { AptlyPage, AptlyScopes } from '@aptly-as/types';
import { useCallback, useContext } from 'react';
import { GridColDef } from '@mui/x-data-grid';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import { EditButtonWithIcon } from '../../components/actions/buttons/Buttons';
import useScopeLevel from '../../libraries/scope/useScopeLevel';
import { SlugLevel, useApiUrl, useSlugs, useTo } from '../../hooks/useGetApiUrl';
import Search, { SearchFieldText } from '../../components/Search/Search';
import i18n from '../../libraries/i18n';
import SearchCreateAction from '../../components/Search/search-actions/SearchCreateAction';
import SearchDataGrid from '../../components/Search/SearchDataGrid';
import { ICrudPage, pageSchema } from './page.schema';
import {
  dataGridFieldClickColumn,
  dataGridLevelColumn,
  dataGridSearchNameColumn,
  dataGridSearchReferenceColumn,
} from '../../components/Search/search-data-grid/data-grid.fields';
import { pageIcons } from './page.types';
import {
  dataGridActionProps,
  DataGridActionWrapper,
  DataGridCopyAction,
  DataGridDeleteAction,
  DataGridEditAction,
  DataGridExportAction,
  DataGridGoToAction,
  DataGridPreviewAction,
} from '../../components/Search/search-data-grid/data-grid.actions';
import SearchImportAction from '../../components/Search/search-actions/SearchImportAction';
import { importSchema } from '../../components/crud/schema/extends.schemas';
import { ProjectContext } from '../Project/ProjectContext';
import { toSearchPatchPath } from '../../components/Search/search.utils';
import useScopeProps from '../../libraries/scope/useScopeProps';

const columns = (): GridColDef[] => [
  dataGridFieldClickColumn<ICrudPage, 'icon'>('icon', i18n.t('singles.icon'), (params) => {
    if (params.value) {
      const icon = pageIcons.find((x) => x.value === params.value);
      return icon?.label || params.value || 'N/A';
    }
    return 'N/A';
  }),
  dataGridSearchNameColumn(),
  {
    type: 'string',
    field: 'summary',
    flex: 1,
    headerName: i18n.t('paragraphs.linkDescription'),
  },
  dataGridSearchReferenceColumn('upsellTemplate', i18n.t('singles.upsellTemplate'), () => ({
    endpoint: useApiUrl(SlugLevel.Project, 'upsell-templates'),
    query: { select: '_id,name', as: 'array', limit: '5', sort: 'name', level: 'project' },
    singular: true,
  })),
  dataGridLevelColumn(),
  dataGridActionProps(
    (params) => {
      const [slug, project] = useSlugs();
      const preTo = useTo(SlugLevel.Project, '/sider');
      let to = `dashboard/organizations/${slug}`;
      if (project) {
        to += `/projects/${project}`;
      }
      to += `/page/${params.row._id}`;
      return (
        <DataGridActionWrapper>
          <DataGridPreviewAction to={to} />
          <DataGridEditAction params={params} checkIfDisabledInProject />
          <DataGridCopyAction<AptlyPage>
            params={params}
            preCopy={(data) => ({
              name: `${data.name} - kopi`,
              icon: data.icon,
              summary: data.summary,
              sections: data.sections,
              upsellTemplate: data.upsellTemplate,
            })}
          />
          <DataGridExportAction params={params} />
          <DataGridDeleteAction params={params} checkIfDisabledInProject />
          <DataGridGoToAction params={params} preTo={preTo} />
        </DataGridActionWrapper>
      );
    },
    { minWidth: 330 }
  ),
];

export default function Pages() {
  const project = useContext(ProjectContext);
  const path = useApiUrl(SlugLevel.Project, 'pages');
  const scope = useScopeProps({
    project: AptlyScopes.ProjectPages,
    organization: AptlyScopes.OrganizationPages,
    base: AptlyScopes.Admin,
  });

  const buttonActions = useCallback(() => {
    const a = [
      <SearchImportAction key="import" path={`${path}/import`} schema={importSchema()} />,
      <SearchCreateAction key="search" />,
    ];

    if (project?.actions?.spawnPatch) {
      a.unshift(
        <EditButtonWithIcon
          onClick={() =>
            project.actions.spawnPatch(['pages'], {
              useFieldProps: {
                initWithGet: true,
                reachOptions: { query: { $populate: 'pages:_id,name', select: '_id,pages' } },
              },
            })
          }
          endIcon={<HelpOutlineOutlinedIcon />}
          title={i18n.t('singles.informationPages')}
        />
      );
    }

    return a;
  }, [project, path]);

  return (
    <Search
      path={path}
      patchPath={toSearchPatchPath('pages')}
      level={useScopeLevel()}
      scope={scope}
      header={{ title: i18n.t('singles.pages') }}
      post={{ title: i18n.t('singles.pages') }}
      patch={{ title: (data) => String(data.name) }}
      schema={pageSchema()}
      fields={['name', 'upsellTemplate', 'summary', 'icon']}
      options={{ disableBulk: true }}
      reach={{
        query: { sort: 'name', $populate: 'upsellTemplate:_id,name', archived: false, level: 'project' },
      }}
      buttonActions={buttonActions}
      searchFields={[
        <SearchFieldText key="name" field="name" queryKey="$name" label={i18n.t('singles.name')} autoFocus />,
      ]}
    >
      {() => <SearchDataGrid columns={columns()} />}
    </Search>
  );
}
