import type { GridRowParams } from '@mui/x-data-grid/models/params';
import { useCallback, useContext, useMemo, useState } from 'react';
import { AptlyFieldType, AptlyScopes, AptlyUnitTemplate } from '@aptly-as/types';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import GridOn from '@mui/icons-material/GridOn';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import LocationCity from '@mui/icons-material/LocationCity';
import { useNavigate } from 'react-router-dom';
import { CopyIconButton, DownloadIconButton } from '../../components/actions/icons/Icons';
import { schemaSelectAlgorithm } from '../../components/crud/schema/fields.schema.js';
import { createModal } from '../../containers/Modal/ModalContext';
import { useAlgorithms } from '../Algorithm/useAlgorithms';
import { usePeriods } from '../Period/usePeriods';
import i18n from '../../libraries/i18n';
import { SlugLevel, useApiUrl } from '../../hooks/useGetApiUrl';
import Search, { SearchFieldText } from '../../components/Search/Search';
import SearchCreateAction from '../../components/Search/search-actions/SearchCreateAction';
import SearchDataGrid from '../../components/Search/SearchDataGrid';
import {
  dataGridLevelColumn,
  dataGridSearchNameColumn,
} from '../../components/Search/search-data-grid/data-grid.fields';
import SearchImportAction from '../../components/Search/search-actions/SearchImportAction';
import {
  dataGridActionProps,
  DataGridActionWrapper,
  DataGridDeleteAction,
  DataGridEditAction,
  DataGridExportAction,
  DataGridGoToAction,
} from '../../components/Search/search-data-grid/data-grid.actions';
import {
  ICrudUnitTemplate,
  periodSchema,
  unitTemplateImportSchema,
  unitTemplateSchema,
} from './unit-template.schema';
import { IProjectContext, ProjectContext } from '../Project/ProjectContext';
import { UnitTemplate as UnitTemplateType } from './unit-template.types';
import { Switch } from '../../mui/Input';
import filesSaver from '../../libraries/files-saver';
import Grid from '../../mui/Grid';
import { SearchCrudContext, toSearchPatchPath } from '../../components/Search/search.utils';
import { useDataGridActionProps } from '../../hooks/useDataGridActionProps';
import { Post } from '../../components/simple/useSimplePost';
import { ICrudSchema } from '../../components/crud/utils/crud.utils';
import FileCopyOutlined from '@mui/icons-material/FileCopyOutlined';
import useScopeProps from '../../libraries/scope/useScopeProps';

const gridColumns = (project: IProjectContext): GridColDef[] => [
  dataGridSearchNameColumn(),
  dataGridLevelColumn(),
  dataGridActionProps(
    (params) =>
      !project.data || params.row.project ? (
        <DataGridActionWrapper>
          <DataGridDeleteAction params={params} />
          <DataGridExportAction
            params={params}
            exportProps={{
              icon: <GridOn />,
              title: i18n.t('actions.downloadCSVTemplate'),
              data: { format: 'csv' },
            }}
          />
          <DownloadIconButton
            onClick={() => createModal(<DownloadUnitCsvTemplate unitTemplate={params.row} />)}
            title={i18n.t('actions.exportUnitTemplate')}
            icon={<LocationCity />}
          />
          <DataGridExportAction params={params} />
          <CopyUnitTemplate params={params} />
          <DataGridEditAction params={params} />
          <DataGridGoToAction params={params} />
        </DataGridActionWrapper>
      ) : (
        <DataGridActionWrapper>
          <CopyUnitTemplate params={params} />
        </DataGridActionWrapper>
      ),
    {
      minWidth: 380,
    }
  ),
];

export default function UnitTemplates() {
  const navigate = useNavigate();
  const project = useContext(ProjectContext);
  const path = useApiUrl(SlugLevel.Project, 'unit-templates');
  const scope = useScopeProps({
    project: AptlyScopes.ProjectUnitTemplates,
    organization: AptlyScopes.OrganizationUnitTemplates,
    base: AptlyScopes.Admin,
  });
  const algorithms = useAlgorithms();
  const periods = usePeriods();
  const patchPath = useMemo(() => toSearchPatchPath('unit-templates'), []);
  const columns = useMemo(() => gridColumns(project), [project]);

  const buttonActions = useCallback(() => {
    return [
      <SearchImportAction
        key="import"
        path={`${path}/import`}
        fields={!project.data ? ['file', 'algorithm'] : undefined}
        schema={unitTemplateImportSchema(algorithms, periods)}
      />,
      <SearchCreateAction key="create" />,
    ];
  }, [path, project, algorithms, periods]);

  const handleOnRowClick = useCallback(
    (params: GridRowParams) => {
      if (params.row.project || scope === AptlyScopes.OrganizationUnitTemplates) {
        navigate(String(params.id));
      }
    },
    [navigate]
  );

  return (
    <Search<ICrudUnitTemplate>
      path={path}
      patchPath={patchPath}
      scope={scope}
      header={{ title: i18n.t('singles.optionLists') }}
      post={{ title: i18n.t('singles.optionList') }}
      patch={{ title: (data) => String(data.name) }}
      schema={unitTemplateSchema()}
      fields={['name']}
      options={{ disableBulk: true }}
      reach={{
        query: {
          sort: '-project,name',
          archived: false,
          select: '_id name organization project',
          level: 'project',
        },
      }}
      buttonActions={buttonActions}
      searchFields={[
        <SearchFieldText key="name" field="name" queryKey="$name" label={i18n.t('singles.name')} autoFocus />,
      ]}
    >
      {() => <SearchDataGrid columns={columns} onRowClick={handleOnRowClick} />}
    </Search>
  );
}

interface DownloadUnitCsvTemplateProps {
  unitTemplate: UnitTemplateType;
  onClose?: () => void;
}

function DownloadUnitCsvTemplate({ onClose, unitTemplate }: DownloadUnitCsvTemplateProps) {
  const endpoint = useApiUrl(SlugLevel.Project, 'unit-templates');
  const project = useContext(ProjectContext);
  const projectName = project.data.name;
  const [data, setData] = useState<{ [key: string]: boolean | string }>({
    format: 'unit',
    allFields: false,
    kvmGulv: false,
    kvmVegg: false,
    lmList: false,
  });

  const mySwitch = (key: string, title: string) => (
    <Switch
      checked={Boolean(data[key])}
      onChange={() => setData((s) => ({ ...s, [key]: !data[key] }))}
      label={i18n.t(title)}
    />
  );

  const fileName = `${projectName}-${unitTemplate.name}-${i18n.t('singles.units')}-${i18n.t(
    'actions.export'
  )}.csv`;

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        filesSaver({
          endpoint: `${endpoint}/${unitTemplate._id}/export`,
          fileName,
          data,
        });
      }}
    >
      <DialogTitle>{i18n.t('paragraphs.selectFieldsToFill')}</DialogTitle>
      <DialogContent>
        <Grid container direction="column">
          {mySwitch('allFields', 'paragraphs.generalFields')}
          {mySwitch('kvmGulv', 'paragraphs.squareMetersFloor')}
          {mySwitch('kvmVegg', 'paragraphs.squareMetersWall')}
          {mySwitch('lmList', 'paragraphs.lengthMetersMoldings')}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent="flex-end" spacing={1}>
          <Grid item>
            <Button variant="text" onClick={onClose} type="button">
              {i18n.t('actions.close')}
            </Button>
          </Grid>
          <Grid item>
            <Button variant="outlined" color="primary" type="submit">
              {i18n.t('actions.download')}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </form>
  );
}

interface CopyUnitTemplateProps {
  params: GridRenderCellParams;
}
function CopyUnitTemplate({ params }: CopyUnitTemplateProps) {
  const { scope, patchPath } = useContext(SearchCrudContext);
  const periods = usePeriods();
  const algorithms = useAlgorithms();
  const project = useContext(ProjectContext);
  const schema = useMemo<ICrudSchema<{ period: string; project?: string }>>(
    () => ({
      period: periodSchema(periods),
      algorithm: schemaSelectAlgorithm(algorithms),
      project: {
        type: AptlyFieldType.Hidden,
        defaultValue: undefined,
        label: '',
      },
    }),
    [periods]
  );

  const onAction = useCallback(
    ({ unshift }: any, data: AptlyUnitTemplate) => {
      createModal(
        <Post<any>
          path={`${patchPath(data)}/${data._id}/copy`}
          schema={schema as any}
          data={project.data ? { project: project.data._id } : {}}
          onCreate={unshift}
          props={{ title: `${i18n.t('actions.copy')} ${data.name}` }}
          fields={project.data && !data.project ? ['period', 'algorithm', 'project'] : []}
          actionButtonProps={{
            actionLabel: i18n.t('actions.copy'),
            actionIcon: <FileCopyOutlined />,
          }}
        />
      );
    },
    [project, patchPath, schema]
  );

  const onClick = useDataGridActionProps(params, onAction);
  if (!scope.create) return null;
  return <CopyIconButton onClick={onClick} />;
}
